import React, { useState } from 'react';

import { css, commonStyles } from '../styling';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, Menu, TableCell } from '@mui/material';

type Props = {
  children: React.ReactNode;
};

const MenuTableCell: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e: any): void => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: any): void => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <TableCell
      align="right"
      style={{ padding: '5px', paddingRight: '10px' }}
      onClick={(e): void => e.stopPropagation()}
      className={css(commonStyles.clickable)}
    >
      <IconButton
        size="small"
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} onClick={handleClose}>
        {props.children}
      </Menu>
    </TableCell>
  );
};

export default MenuTableCell;
