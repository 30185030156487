import React, { useState, useEffect, ReactElement, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { RotateLoader } from 'react-spinners';
import { Button, Dialog, Divider } from '@mui/material';

import { useNotify } from '../Common/snackbarHooks';
import { BackLink } from '../Common/ButtonLinks';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { commonStyles, css } from '../Common/styling';
import { AuthContextValue, OptionsInterface, RoleOptionsInterface } from '../Common/types';
import AuthContext from '../Common/AuthContext';

import UserForm from './components/UserForm';
import { getTeamMinimalList } from '../Team/teamApi';
import { UserInterface, PasswordPayloadInterface } from './types';
import { validationSchema, initialValues } from './usersSchema';
import { getUser, updateUser, changePassword } from './usersApi';
import { getRolesMinimalList } from '../Common/commonApi';
import CommentModal from './components/CommentModal';
import ProfilePasswordForm from './Profile/components/ProfilePasswordForm';
import { passwordValues, passwordValidationSchema } from './Profile/profileSchema';
import appTheme from '../Common/styling/appTheme';
import { getDefaultsWithActualValues } from '../Common/utilities';
import PayslipsModal from './components/PayslipsModal';
import BouncingDots from '../Common/BouncingDots';

type Props = {
  isProfile: boolean;
};

const EditUser: React.FC<Props> = (props) => {
  // hooks
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();
  const { id } = useParams() as { id: string };
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [user, setUser] = useState<UserInterface>();
  const [rolesMinimalList, setRolesMinimalList] = useState<Array<RoleOptionsInterface>>([]);
  const [teamMinimalList, setTeamMinimalList] = useState<Array<OptionsInterface>>([]);
  const [commentModalState, setCommentModalState] = useState(false);
  const [payslipsModalState, setPayslipsModalState] = useState(false);

  // functions
  const { profile, hasPermissions } = useContext(AuthContext) as AuthContextValue;

  useEffect(() => {
    fetchUser(id);
    fetchRolesMinimalList();
    fetchTeamsMinimalList();
  }, []); // eslint-disable-line

  useEffect(() => {
    fetchUser(id);
  }, [id]); // eslint-disable-line

  const fetchUser = (id: string): void => {
    setLoadingUser(true);
    getUser(id)
      .then(({ data }) => {
        setUser({ ...data.data, role_id: data.data.role.id, team_id: data.data.team.id });
      })
      .catch(() => notifyError('Det gick inte att hämta användaren'))
      .finally(() => setLoadingUser(false));
  };

  const fetchRolesMinimalList = (): void => {
    setLoadingRoles(true);
    getRolesMinimalList({ hide_banned: true })
      .then(({ data }) => {
        setRolesMinimalList(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över roll'))
      .finally(() => setLoadingRoles(false));
  };

  const fetchTeamsMinimalList = (): void => {
    setLoadingTeams(true);
    getTeamMinimalList({ is_active: true })
      .then(({ data }) => setTeamMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över teams'))
      .finally(() => setLoadingTeams(false));
  };

  const handleFormSubmit = (
    { person_nr, salary_detail_attributes, role, team, ...values }: typeof initialValues,
    actions: FormikHelpers<any>
  ): void => {
    if (!user) return;
    setLoading(true);
    if (!(hasPermissions(['admin', 'operational_leader', 'operational_manager']) || profile?.id === user.id)) {
      person_nr = '';
      salary_detail_attributes = { account_number: '', clearing_number: '' };
    }
    updateUser(id, {
      ...values,
      person_nr,
      salary_detail_attributes,
      role_id: role?.id as number,
      team_id: team?.id as number,
    })
      .then(() => notifySuccess('Användaren har uppdaterats'))
      .then(() => {
        if (props.isProfile) {
          navigate(0);
        } else {
          navigate(`/users`);
        }
      })
      .catch((error: any) => {
        notifyError(`Det gick inte att uppdatera användaren. \n${HandleError(error.response.data)}`);
        actions.setSubmitting(false);
        setLoading(false);
      });
  };

  const handlePasswordFormSubmit = (values: PasswordPayloadInterface): void => {
    if (!user) return;
    changePassword(user.id, values)
      .then(() => {
        notifySuccess('Lösenordet är ändrat');
        fetchUser(id);
      })
      .catch(() =>
        notifyError('Det gick inte att uppdatera lösenordet. Lösenordet behöver vara mellan 8 och 20 tecken långt.')
      );
  };

  // render
  return (
    <div>
      {/* Title */}
      <div className="flex items-center justify-between h-18 pt-3 mx-8 mb-5">
        <div className="flex items-center">
          {!props.isProfile && <BackLink link="/users" />}
          <h1 className="text-2xl font-semibold">
            {props.isProfile ? 'Profil' : 'Redigera'}{' '}
            {user ? (
              (() => {
                const userName = `${user.first_name} ${user.surname}`;
                const employmentNr = props.isProfile ? '' : ` (nr ${user.employment_nr})`;
                return `${userName}${employmentNr}`;
              })()
            ) : (
              <BouncingDots />
            )}
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div>
            {(hasPermissions(['admin']) ||
              (hasPermissions(['operational_leader', 'operational_manager', 'regional_manager']) &&
                profile?.id === user?.id)) && (
              <Button
                style={{
                  borderColor: appTheme.themeGreen,
                  color: appTheme.themeGreen,
                }}
                variant="outlined"
                onClick={(): void => setPayslipsModalState(true)}
              >
                Lönebesked
              </Button>
            )}
          </div>
          <div className="ml-5">
            {hasPermissions(['admin', 'operational_leader', 'operational_manager']) && (
              <Button
                style={{
                  borderColor: appTheme.themeGreen,
                  color: appTheme.themeGreen,
                }}
                variant="outlined"
                onClick={(): void => setCommentModalState(true)}
              >
                Kommentar
              </Button>
            )}
          </div>
        </div>
      </div>

      {loading || loadingRoles || loadingTeams || loadingUser || !user ? (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading || loadingRoles || loadingTeams || loadingUser} />
        </div>
      ) : (
        <>
          <div className={css(commonStyles.formContainer)}>
            <Formik
              initialValues={getDefaultsWithActualValues(initialValues, user)}
              validationSchema={validationSchema}
              validateOnMount={true}
              validateOnChange={true}
              onSubmit={(values, actions): void => {
                handleFormSubmit(values, actions);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                isValid,
                dirty,
              }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <UserForm
                    user={user}
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    isCreate={false}
                    rolesMinimalList={rolesMinimalList}
                    teamMinimalList={teamMinimalList}
                    setFieldValue={setFieldValue}
                    submitDisabled={isSubmitting || !isValid || !dirty}
                  />
                </form>
              )}
            </Formik>
            {commentModalState && hasPermissions(['admin', 'operational_leader', 'operational_manager']) && (
              <Dialog
                open={commentModalState}
                onClose={(): void => {
                  setCommentModalState(false);
                }}
                fullWidth={true}
                maxWidth="sm"
              >
                <CommentModal user={user} setModalState={setCommentModalState} />
              </Dialog>
            )}
            {payslipsModalState &&
              (hasPermissions(['admin']) ||
                (hasPermissions(['operational_leader', 'operational_manager', 'regional_manager']) &&
                  profile?.id === user?.id)) && (
                <PayslipsModal
                  user={user}
                  isVisible={payslipsModalState}
                  onClose={(): void => setPayslipsModalState(false)}
                />
              )}
          </div>
          {props.isProfile && (
            <>
              <Divider style={{ marginTop: '20px' }} />
              <div style={{ alignSelf: 'right', marginTop: '20px', marginLeft: '30px', fontSize: '18px' }}>
                <h1 className={css(commonStyles.headerTextStyle)}>
                  Byta lösenord för {user.first_name + ' ' + user.surname}
                </h1>
              </div>
              <div className={css(commonStyles.formContainer)}>
                <Formik
                  enableReinitialize
                  initialValues={passwordValues}
                  validationSchema={passwordValidationSchema}
                  onSubmit={(values): void => handlePasswordFormSubmit(values)}
                >
                  {({ values, errors, handleChange, handleSubmit, setFieldValue }): ReactElement => (
                    <form onSubmit={handleSubmit}>
                      <ProfilePasswordForm
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        setFieldValue={setFieldValue}
                      />
                    </form>
                  )}
                </Formik>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EditUser;
